<template>
  <div id="index">
    <div class="top-index" >
      <div class="bt-index">
        <router-link to="/buyManage?selected=1"><Button type="info">采购</Button></router-link>
        <router-link to="/saleManage?selected=1"><Button type="success">销售</Button></router-link>
        <router-link to="/goodsManage?selected=1"><Button type="warning">库存</Button></router-link>
      </div>
      <div class="select-date">
        <Select size="small" v-model="search_m.date_type" @on-change="searchSubmit()"  style="width:50px" placeholder="请选择类型">
          <Option value="date" key="date">日</Option>
          <Option value="month" key="month">月</Option>
          <Option value="year" key="year">年</Option>
        </Select>&nbsp;

        <DatePicker size="small" v-model="search_m.created_at" :type="search_m.date_type" placement="bottom-end"
                    placeholder="请选择时间" style="width: 160px"></DatePicker>
        &nbsp;&nbsp;
        <Button type="primary" size="small" @click="searchSubmit()" shape="circle"
                icon="ios-search">搜索
        </Button>
        &nbsp;&nbsp;
        <Button type="default" size="small" @click="cancelSearch()" shape="circle">重置</Button>
      </div>

    </div>

    <div class="mt-20">
      <Row :gutter="20">
        <Col span="6">
          <router-link to="/buyManage?selected=1">
          <Card class="top-line">
            <div class="card">
              <div class="title"><h2>采购概览</h2></div>
              <div class="sumInfo">
                  <div>
                    <span style="width:100px;display:inline-block;text-align:right;">采购总金额：</span>
                    <span class="main-color">{{ buyPreview.money }}</span>
                  </div>
                  <div class="mt-20">
                    <span style="width:100px;display:inline-block;text-align:right;">已付金额：</span>
                    <span class="main-color">{{ buyPreview.cash_money }}</span>
                  </div>
              </div>
            </div>
          </Card>
          </router-link>
        </Col>

        <Col span="6">
          <router-link to="/saleManage?selected=1">
          <Card class="top-line">
            <div class="card">
              <div class="title"><h2>销售概览</h2></div>
              <div class="sumInfo">
                <div>
                  <span style="width:100px;display:inline-block;text-align:right;">销售总金额：</span>
                  <span class="main-color">{{ salePreview.money }}</span>
                </div>
                <div style="margin-top:20px;">
                  <span style="width:100px;display:inline-block;text-align:right;">已收金额：</span>
                  <span class="main-color">{{ salePreview.cash_money }}</span>
                </div>
              </div>
            </div>
          </Card>
          </router-link>
        </Col>

        <Col span="6" v-show="parseInt(special_rights_gross_profit)==1">
          <router-link to="/goodsManage?selected=1">
            <Card class="top-line">
              <div class="card">
                <div class="title"><h2>毛利概览</h2></div>
                <div class="sumInfo">
                  <div>
                    <span style="width:100px;display:inline-block;text-align:right;">毛利金额：</span>
                    <span class="main-color">{{ grossProfit.money }}</span>
                  </div>
                </div>
              </div>
            </Card>
          </router-link>
        </Col>

        <Col span="6">
          <router-link to="/goodsManage?selected=1">
            <Card class="top-line">
              <div class="card">
                <div class="title"><h2>库存概览</h2></div>
                <div class="sumInfo">
                  <div>
                    <span style="width:100px;display:inline-block;text-align:right;">库存金额：</span>
                    <span class="main-color">{{ stockPreview.money }}</span>
                  </div>
                </div>
              </div>
            </Card>
          </router-link>
        </Col>
      </Row>

      <Row :gutter="20" class="mt-20">
        <Col span="12">
          <Card class="top-line">
            <div slot="title" style="display:flex;justify-content: center;align-items: center;text-align:center;">
              <p>
                供应商账本
                <span class="disabled-color fs-12">总计:</span>
                <span class="ml-10 money-color fw-bold">{{supplier_ledger.all_money}}</span>
<!--                <span class="ml-10 fs-12 disabled-color" style="width:116px;">数据每分钟更新</span>-->
              </p>
            </div>
            <div>
              <Table  size="small" @on-row-click="getBuyClientDetail"  :columns="supplier_ledger.columns" :data="supplier_ledger.list">

              </Table>
            </div>
            <Row style="flex-flow:row-reverse wrap">
              <Page size="small" :total="supplier_ledger.list_total" :current.sync="supplier_ledger.current_page"
                    :page-size="supplier_ledger.page_size" show-elevator
                    show-sizer
                    @on-change="buyChangePage" @on-page-size-change="buyChangePageSize"
                    class="mt10"/>
            </Row>
          </Card>
        </Col>

        <Col span="12">
          <Card class="top-line">
            <div slot="title" style="display:flex;justify-content: space-between;align-items: center;text-align:center;">
              <p>客户账本
                <span class="disabled-color fs-12">总计:</span>
                <span class="ml-10 money-color fw-bold">{{client_ledger.all_money}}</span>
              </p>
            </div>
            <div>
              <Table  size="small" @on-row-click="getSaleClientDetail"  :columns="client_ledger.columns" :data="client_ledger.list">
              </Table>
            </div>
            <Row style="flex-flow:row-reverse wrap">
              <Page size="small" :total="client_ledger.list_total" :current.sync="client_ledger.current_page"
                    :page-size="client_ledger.page_size" show-elevator
                    show-sizer
                    @on-change="saleChangePage" @on-page-size-change="saleChangePageSize"
                    class="mt10"/>
            </Row>
          </Card>
        </Col>
      </Row>
    </div>

    <Modal v-model="buy_client.modal" title="供应商账本明细" width="1280">
      <Row class="display-block">
        <Table :columns="buy_client.columns" :data="buy_client.list">
          <template slot-scope="{ row }" slot="abstract_info">
            <span class="fs-12">{{ row.abstract_info }}</span>
          </template>

          <template slot-scope="{ row }" slot="action">
            <Button  type="text" size="small"
                    class="main-font-color fs-12" @click="viewBuyOrder(row.id)">
              <Icon type="ios-checkmark-circle"/>
              查看
            </Button>
          </template>
        </Table>
      </Row>
    </Modal>

    <Modal v-model="sale_client.modal" title="客户账本明细" width="1280">
      <Row class="display-block">
        <Table  :columns="sale_client.columns" :data="sale_client.list">
          <template slot-scope="{ row }" slot="abstract_info">
            <span class="fs-12">{{ row.abstract_info }}</span>
          </template>

          <template slot-scope="{ row }" slot="action">
            <Button  type="text" size="small"
                     class="main-font-color fs-12" @click="viewSaleOrder(row.id)">
              <Icon type="ios-checkmark-circle"/>
              查看
            </Button>
          </template>
        </Table>
      </Row>
    </Modal>

    <BuyDetail ref="buy_detail" @payBuyOperate="payBuyOperate"/>

    <SaleDetail ref="sale_detail" @paySaleOperate="paySaleOperate"/>

  </div>
</template>

<script>
    import {apiGetBuyClientMoney, apiGetBuyClientDetail} from "@/api/buy/buy";
    import {apiGetSaleClientMoney, apiGetSaleClientDetail} from "@/api/sale/sale";

    import {apiGetStockPreview} from "@/api/goods/goods"

    import BuyDetail from '@/components/common/BuyDetail'
    import SaleDetail from '@/components/common/SaleDetail'

    export default {
      name: "Index",
      data() {
          return {
            special_rights_gross_profit: 0,
            search_m: {
              created_at: '',
              date_type: 'date',
            },
            searchFlag:0,
            buy_client: {
              modal: false,
              client_id: 0,
              list: [],
              list_total:0,
              page_size:10,
              current_page: 1,
              columns: [
                {
                  title: '采购单号',
                  key: 'buy_no',
                  align: 'center',
                },
                {
                  title: '摘要',
                  key: 'abstract_info',
                  slot: 'abstract_info',
                  minWidth:260,
                  align: 'left',
                },
                {
                  title: '数量',
                  key: 'goods_num',
                  align: 'center',
                },
                {
                  title: '应付金额',
                  key: 'left_pay',
                  align: 'center',
                },
                {
                  title: '操作',
                  key: 'action',
                  slot: 'action',
                  align: 'center',
                },
              ]
            },
            sale_client: {
              modal: false,
              client_id: 0,
              list: [],
              list_total:0,
              page_size:10,
              current_page: 1,
              columns: [
                {
                  title: '销售单号',
                  key: 'sale_no',
                  align: 'center',
                },
                {
                  title: '摘要',
                  key: 'abstract_info',
                  slot: 'abstract_info',
                  minWidth:260,
                  align: 'left',
                },
                {
                  title: '数量',
                  key: 'goods_num',
                  align: 'center',
                },
                {
                  title: '应收金额',
                  key: 'left_pay',
                  align: 'center',
                },
                {
                  title: '操作',
                  key: 'action',
                  slot: 'action',
                  align: 'center',
                },
              ]
            },


            buyPreview: {
              money: 0,
              cash_money: 0,
              book_money: 0,
            },
            salePreview: {
              money: 0,
              cash_money: 0,
              book_money: 0,
            },
            grossProfit: {
              money: 0,
            },
            stockPreview: {
              money: 0,
            },

            supplier_ledger: {
              list: [],
              list_total:0,
              page_size:10,
              current_page: 1,
              columns: [
                {
                  title: '供应商编号',
                  key: 'client_no',
                  align: 'center',
                },
                {
                  title: '供应商名称',
                  key: 'client_name',
                  align: 'center',
                },
                {
                  title: '应付金额',
                  key: 'total',
                  align: 'center',
                },
              ]
            },
            client_ledger: {
              list: [],
              list_total:0,
              page_size:10,
              current_page: 1,
              columns: [
                {
                  title: '客户编号',
                  key: 'client_no',
                  align: 'center',
                },
                {
                  title: '客户名称',
                  key: 'client_name',
                  align: 'center',
                },
                {
                  title: '应收金额',
                  key: 'total',
                  align: 'center',
                },
              ]
            }
          }
      },
      methods:{
        payBuyOperate(data) {
          this.getBuyClientDetailList();
        },
        paySaleOperate(data) {
          this.getSaleClientDetailList();
        },
        viewBuyOrder(id) {
          this.$refs.buy_detail.checkOrderInfo(id);
        },
        viewSaleOrder(id) {
          this.$refs.sale_detail.checkOrderInfo(id);
        },
        buyChangePage(page) {
          this.supplier_ledger.current_page = page;
          this.getBuyClientMoney();
        },
        saleChangePage(page) {
          this.client_ledger.current_page = page;
          this.getSaleClientMoney();
        },
        buyChangePageSize(pageSize) {
          this.supplier_ledger.page_size = pageSize;
          this.supplier_ledger.current_page = 1;
          this.getBuyClientMoney();
        },
        saleChangePageSize(pageSize) {
          this.client_ledger.page_size = pageSize;
          this.client_ledger.current_page = 1;
          this.getSaleClientMoney();
        },
        cancelSearch() {
          this.searchFlag = 0;
          this.search_m.created_at = '';
          this.search_m.date_type = "date";
          this.getPreview();
        },
        searchSubmit() {
          this.searchFlag = 1;
          this.getPreview();
        },
        getPreview() {
          if (this.search_m.created_at) {
            this.search_m.created_at = this.switchTimeFormat(this.search_m.created_at);
          }

          this.getFlowPreview()
        },
        switchTimeFormat(time) {
          const dateTime = new Date(time);
          const year = dateTime.getFullYear();
          const month = dateTime.getMonth() + 1;
          const date = dateTime.getDate();
          return year + '-' + this.addZero(month) + '-' + this.addZero(date);
        },
        addZero(v) {
          return v < 10 ? '0' + v : v
        },
        getFlowPreview($created_at = '') {
          let $date_type = this.search_m.date_type;
          if (this.searchFlag) {
            $created_at = this.search_m.created_at;
          }
          let param = {
            created_at: $created_at,
            date_type: $date_type
          };

            this.$axios.post('/api/finance/getFlowPreview', param).then((response) => {
              if (response.data.err_no == 0) {
                let buy = response.data.results.buy;
                let sale = response.data.results.sale;
                let grossProfit = response.data.results.grossProfit;

                //采购概览
                this.buyPreview.money = parseFloat(buy.sum? buy.sum : 0);
                this.buyPreview.cash_money = parseFloat(buy.cash ? buy.cash : 0);
                this.buyPreview.book_money = parseFloat(buy.book ? buy.book : 0);

                //销售概览
                this.salePreview.money = parseFloat(sale.sum ? sale.sum : 0);
                this.salePreview.cash_money = parseFloat(sale.cash ? sale.cash : 0);
                this.salePreview.book_money = parseFloat(sale.book ? sale.book : 0);

                this.grossProfit.money = parseFloat(grossProfit.money ? grossProfit.money : 0);
              }
            });
        },
        getBuyClientMoney() {
          let param = {
            page: this.supplier_ledger.current_page,
            size: this.supplier_ledger.page_size,
          }
          apiGetBuyClientMoney(param).then(res => {
            if (res.data.err_no == 0) {
              for(let i=0; i<res.data.results.list.length; i++) {
                res.data.results.list[i].total = parseFloat(res.data.results.list[i].total);
              }
              this.supplier_ledger.list = res.data.results.list;
              this.supplier_ledger.all_money = parseFloat(res.data.results.all_money);
            }
          })
        },
        getSaleClientMoney() {
          let param = {
            page: this.client_ledger.current_page,
            size: this.client_ledger.page_size,
          }
          apiGetSaleClientMoney(param).then(res => {
            if (res.data.err_no == 0) {
              for(let i=0; i<res.data.results.list.length; i++) {
                res.data.results.list[i].total = parseFloat(res.data.results.list[i].total);
              }
              this.client_ledger.list = res.data.results.list;
              this.client_ledger.all_money = parseFloat(res.data.results.all_money);
            }
          })
        },
        getBuyClientDetail(row, index) {
          this.buy_client.client_id = row.supplier_id;
          this.buy_client.current_page = 1;
          this.getBuyClientDetailList();
        },
        getSaleClientDetail(row, index) {
          this.sale_client.client_id = row.client_id;
          this.sale_client.current_page = 1;
          this.getSaleClientDetailList();
        },
        getSaleClientDetailList() {
          let param = {
            client_id:this.sale_client.client_id,
            page: this.sale_client.current_page,
            size: this.sale_client.page_size,
          }
          apiGetSaleClientDetail(param).then(res => {
            if (res.data.err_no == 0) {
              let list = [];
              for(let i=0; i<res.data.results.list.length; i++) {
                let tmp = {};
                tmp.id = res.data.results.list[i].id;
                tmp.sale_no = res.data.results.list[i].sale_no;
                tmp.abstract_info = res.data.results.list[i].abstract_info;
                tmp.left_pay = parseFloat(res.data.results.list[i].left_pay);
                tmp.goods_num = parseFloat(res.data.results.list[i].goods_num);
                list.push(tmp);
              }
              this.sale_client.list = list;
              this.sale_client.modal = true;
            }
          })
        },
        getBuyClientDetailList() {
          let param = {
            client_id:this.buy_client.client_id,
            page: this.buy_client.current_page,
            size: this.buy_client.page_size,
          }
          apiGetBuyClientDetail(param).then(res => {
            if (res.data.err_no == 0) {
              let list = [];
              for(let i=0; i<res.data.results.list.length; i++) {
                let tmp = {};
                tmp.id = res.data.results.list[i].id;
                tmp.buy_no = res.data.results.list[i].buy_no;
                tmp.abstract_info = res.data.results.list[i].abstract_info;
                tmp.left_pay = parseFloat(res.data.results.list[i].left_pay);
                tmp.goods_num = parseFloat(res.data.results.list[i].goods_num);
                list.push(tmp);
              }
              this.buy_client.list = list;
              this.buy_client.modal = true;
            }
          })
        },
        getStockPreview() {
          apiGetStockPreview().then(res => {
            if (res.data.err_no == 0) {
              this.stockPreview.money = parseFloat(res.data.results.money);
            }
          });
        },

      },
      mounted() {
        //判断用户是否有毛利权限
        this.special_rights_gross_profit = localStorage.getItem('special_rights_gross_profit');

        this.getPreview();
        this.getStockPreview();
        this.getBuyClientMoney();
        this.getSaleClientMoney();
      },
      components: {
        BuyDetail,
        SaleDetail
      }
    };
</script>

<style scoped>
  #index {
    padding: 23px 40px;
    min-width: 1080px;
    min-height: calc(100vh - 50px);
  }

  .top-line {
    border-top: 3px solid #2775ff;
    border-radius: 4px;
  }

  .card {
    color:#2c3e50;
    height: 170px;
    display:flex;
    flex-direction: column;
    justify-content: center;
    align-items:center;
  }

  .sumInfo {
    margin-top:20px;
    margin-left:-40px;
    font-weight: bold;
    font-size: 15px;
    display:flex;
    flex-direction: column;
  }

  .select-date {
    display: flex;
    justify-content: right;
    align-items: center;
  }

.top-index {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.bt-index {
  width:300px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
</style>
