<template>
<div>
  <!--审核-->
  <Modal v-model="check_order.modal" :title="check_order.title" width="1280">
    <div class="modalForm">
      <Form v-show="!common.view_loading" :model="clientFormItem" :label-width="100" label-colon>
        <Row :gutter="10">
          <Col span="8">
            <FormItem label="销售单号" prop="sale_no">
              <span>{{ clientFormItem.sale_no }}</span>
            </FormItem>
          </Col>
          <Col span="8">
            <FormItem label="客户" prop="client_id">
              <span v-for="item in clientList" v-show="item.client_id===clientFormItem.client_id && (clientFormItem.client_name=item.client_name)">{{ item.client_name }}</span>
            </FormItem>
          </Col>
          <Col span="8">
            <FormItem label="销售日期" prop="sale_date">
              <span>{{ clientFormItem.sale_date }}</span>
            </FormItem>
          </Col>
        </Row>
        <Row :gutter="10">
          <Col span="8">
            <FormItem label="含税" prop="include_tax">
              <span v-if="clientFormItem.include_tax == 1" class="money-color">是</span>
              <span v-else>否</span>
            </FormItem>
          </Col>
          <Col span="8">
            <FormItem label="开票状态" prop="invoicing_status">
              <span v-if="clientFormItem.invoicing_status == 1" class="main-font-color">已开票</span>
              <span v-if="clientFormItem.invoicing_status == 2" class="danger-color">待开票</span>
              <span v-if="clientFormItem.invoicing_status == 0">暂无</span>
            </FormItem>
          </Col>
          <Col span="8">
            <FormItem label="提货方式" prop="pick_way">
              <span v-for="item in pick_way" v-if="clientFormItem.pick_way == item.id" >{{ item.name }}</span>
            </FormItem>
          </Col>
        </Row>
        <Row :gutter="10" v-if="clientFormItem.pick_way==2">
          <Col span="8">
            <FormItem label="物流商" prop="logistics_id">
              <span v-for="i in logisticsList" v-if="clientFormItem.logistics_id == i.id">{{ i.client_name }}({{ i.contact_person }})</span>
            </FormItem>
          </Col>
          <Col span="8">
            <FormItem label="物流单号" prop="logistics_no">
              <span>{{ clientFormItem.logistics_no }}</span>
            </FormItem>
          </Col>
          <Col span="8">
            <FormItem label="物流费用" prop="logistics_pay">
              <span>{{ clientFormItem.logistics_pay }}</span>
            </FormItem>
          </Col>
        </Row>
        <Row :gutter="10">
          <Col span="8">
            <FormItem label="备注" prop="remark">
              <span>{{ clientFormItem.remark }}</span>
            </FormItem>
          </Col>
          <Col span="8">
            <FormItem label="单据状态" prop="status">
              <Tag :style="getStatusColorTg(clientFormItem.status)" class="white-font-color">{{ getStatusText(clientFormItem.status) }}</Tag>
            </FormItem>
          </Col>
          <Col span="8">
            <FormItem label="收款状态" prop="status">
              <span :style="getPayStatusColor(clientFormItem.pay_status)">{{ getPayStatusText(clientFormItem.pay_status) }}</span>
            </FormItem>
          </Col>
        </Row>
        <Row :gutter="10">
          <Col span="8">
            <FormItem label="凭证" prop="flow_time">
              <div class="pointer image-upload-list" v-if="clientFormItem.certificate" v-for="(item, key)  in clientFormItem.certificate">
                <img :src="item.url" alt="" @click="imgShow(key, clientFormItem.certificate)" style="width:100%;height:100%">
              </div>
            </FormItem>
          </Col>

          <Col span="8" v-if="parseInt(special_rights_gross_profit) == 1">
            <FormItem label="毛利">
              <strong class="money-color">{{ clientFormItem.gross_profit}}</strong>
            </FormItem>
          </Col>
        </Row>

        <Row class="display-block">
          <Card dis-hover >
            <div>
              <span style="margin:0 10px" :class="common.view_flag == 1 ? 'select-flag main-font-color pointer': 'pointer'" @click="selectTap(1)">销售商品</span>
              <span style="margin:0 10px" :class="common.view_flag == 3 ? 'select-flag main-font-color pointer': 'pointer'" @click="selectTap(3)">实收金额记录</span>

              <Divider/>
            </div>
            <div v-show="common.view_flag == 1">
              <Table :columns="table_columns_modal" :data="clientFormItem.goods_setted" show-summary
                     :summary-method="addGoodsHandleSummary">
                <template slot-scope="{ row, index }" slot="part_name">
                  <span class="money-color fw-bold fs-12" v-show="row.free_flag==1">[赠]</span>
                  <span>{{row.part_name}}</span>
                </template>
                <template slot-scope="{ row, index }" slot="sale_num">
                  <p>
                    <span>{{ row.sale_num }}</span>
                  </p>
                </template>
                <template slot-scope="{ row }" slot="stock_all">
                  <span>{{minus(row.stock_all, row.frozen_stock)}}</span>
                </template>
                <template slot-scope="{ row, index }" slot="sale_price">
                  <span>{{ row.sale_price }}</span>
                </template>
                <template slot-scope="{ row, index }" slot="action">
                  <span class="disabled-color fs-12">------</span>
                </template>
              </Table>
            </div>
            <div v-show="common.view_flag == 3">
              <Table :columns="common.real_pay_columns" :data="clientFormItem.flow_info">
                <template slot-scope="{ row, index }" slot="money">
                  <strong>{{ row.money }}</strong>
                </template>
              </Table>
            </div>
          </Card>
        </Row>

        <div class="" style="display:flex;justify-content: space-around;">
          <FormItem label="优惠" prop="discount_amount" class="mb-0">
            <span>{{ parseFloat(clientFormItem.discount_amount) }}</span>
          </FormItem>
          <FormItem label="物流运费" prop="logistics_pay" class="mb-0">
            <span>{{ parseFloat(clientFormItem.logistics_pay) }}</span>
          </FormItem>
          <!--            <FormItem label="销售金额" prop="count_price" class="mb-0">-->
          <!--              <strong class="main-font-color">{{ clientFormItem.count_price }}</strong>-->
          <!--            </FormItem>-->

          <FormItem label="销售总额" prop="goods_pay" class="mb-0">
            <strong class="main-font-color">{{ clientFormItem.goods_pay }}</strong>
          </FormItem>

          <FormItem label="本单实收" prop="goods_pay" class="mb-0">
            <strong class="main-font-color">{{ clientFormItem.real_pay }}</strong>
          </FormItem>

          <FormItem label="本单欠款" prop="to_pay" class="mb-0">
            <strong class="money-color">{{ clientFormItem.to_pay }}</strong>
          </FormItem>

          <!--            <FormItem label="实收金额" prop="real_pay" class="mb-0">-->
          <!--              <strong class="main-font-color">{{ clientFormItem.real_pay }}</strong>-->
          <!--              <span v-show="clientFormItem.status == 1 && clientFormItem.pay_desc" class="fs-12 ml-5">({{clientFormItem.pay_desc}})</span>-->
          <!--            </FormItem>-->

          <!--            <FormItem label="计划退款金额" prop="goods_pay" class="mb-0" v-show="[6,9,10,11].includes(clientFormItem.status)">-->
          <!--              <strong class="main-font-color">{{ clientFormItem.return_cost }}</strong>-->
          <!--            </FormItem>-->
        </div>
      </Form>
      <Spin fix v-show="common.view_loading" size="large"></Spin>
    </div>
    <div slot="footer">
      <Button @click="handleResetOrderModal(clientFormItem)">关闭</Button>

      <a target="_blank" style="margin:0 8px;" v-show="clientFormItem.status!=1" :href="'/Print?printType=sale&no='+clientFormItem.id" v-if="aCheck.rightCheck('operate_sales_list')">
        <Button type="primary">打印预览</Button>
      </a>

      <Button type="success" v-show="[0,1,2].includes(clientFormItem.pay_status) && clientFormItem.status!=1" @click="paySaleOrder(clientFormItem)" v-if="aCheck.rightCheck('operate_sales_list')">
        收款
      </Button>
    </div>
  </Modal>

  <!--销售收款-->
  <Modal v-model="pay_sale.modal" title="收款操作" width="800">
    <div class="modalForm">
      <Form ref="pay_sale_item" :model="pay_sale.item" :label-width="100" label-colon>
        <Row :gutter="20">
          <Col span="12" offset="6">
            <FormItem label="订单金额" prop="goods_pay">
              <strong>{{pay_sale.item.goods_pay}}</strong>
            </FormItem>
          </Col>
        </Row>
        <Row :gutter="20">
          <Col span="12" offset="6">
            <FormItem label="已收金额" prop="goods_pay">
              <span class="main-font-color">{{pay_sale.item.real_pay}}</span>
            </FormItem>
          </Col>
        </Row>
        <Row :gutter="20">
          <Col span="12" offset="6">
            <FormItem label="收款金额" prop="current_pay">
              <Input v-model="pay_sale.item.current_pay" placeholder="请填写收款金额" type="number"></Input>
            </FormItem>
          </Col>
        </Row>
        <Row :gutter="20">
          <Col span="12" offset="6">
            <FormItem label="收款时间" prop="pay_time">
              <DatePicker :value="pay_sale.item.pay_time" type="datetime"
                          format="yyyy-MM-dd HH:mm:ss"
                          placeholder="请填写收款时间(不填，默认当前时间)" style="width: 275px"
                          @on-change="getSalePayDateTime"></DatePicker>
            </FormItem>
          </Col>
        </Row>
        <Row :gutter="20">
          <Col span="12" offset="6">
            <FormItem label="说明" prop="pay_desc">
              <Input v-model="pay_sale.item.pay_desc" placeholder="请填写说明"></Input>
              <div v-for="item in common.pay_desc_list" @click="setPayDesc(pay_sale.item, item.name)" class="ivu-tag ivu-tag-size-default ivu-tag-default ivu-tag-checked pointer">
                <span class="c-515a6e">{{ item.name }}</span>
              </div>
            </FormItem>
          </Col>
        </Row>
      </Form>
    </div>
    <div slot="footer">
      <Button @click="closePaySale">关闭</Button>
      <Button type="primary" :loading="pay_sale.modal_loading" @click="paySaleOperate(pay_sale.item)">确认收款</Button>
    </div>
  </Modal>

  <!--图片预览-->
  <ImagePreview name="image_preview" ref="image_preview"/>
</div>
</template>

<script>
import NP from "number-precision";
import * as clientsUtil from "@/utils/client";
import * as logisticsUtil from "@/utils/logistics";
import {apiGetPayDescAll} from "@/api/setting/payDesc";
import {apiPaySaleOperate} from "@/api/sale/sale";
import ImagePreview from "@/components/common/Image/ImagePreview";

export default {
  name: "SaleDetail",
  data() {
    return {
      pay_sale: {
        modal:false,
        modal_loading: false,
        item: {
          id: 0,
          real_pay: 0,
          goods_pay: 0,
          current_pay: '',
          pay_time: '',
          pay_desc: '',
        }
      },
      special_rights_gross_profit: 0,
      table_columns_modal: [
        {
          title: '商品编号',
          key: 'part_no',
          align: 'center',
        },
        {
          title: '商品名称',
          key: 'part_name',
          slot: 'part_name',
          minWidth: 200,
          align: 'left',
        },
        // {
        //   title: '缩略图',
        //   key: 'thumbnail_url',
        //   slot: 'thumbnail_url',
        //   align: 'center',
        // },
        {
          title: '销售数量',
          key: 'sale_num',
          slot: 'sale_num',
          align: 'center',
        },
        {
          title: '销售单价',
          key: 'sale_price',
          slot: 'sale_price',
          align: 'center',
        },
        {
          title: '金额',
          key: 'sale_money',
          align: 'center',
        },
        {
          title: '可用库存',
          slot: 'stock_all',
          key: 'stock_all',
          align: 'center',
        },
        {
          title: '操作',
          key: 'action',
          width: 180,
          slot: 'action',
          align: 'center',
        }
      ],
      modal_loading: {
        success: false,
        add_edit: false,
      },
      check_order: {
        title: '查看订单信息',
        modal: false,
      },
      common: {
        view_loading: false,
        view_flag: 1,
        real_pay_columns: [
          {
            title: '流水单号',
            key: 'flow_id',
            align: 'center',
          },
          {
            title: '金额',
            key: 'money',
            slot: 'money',
            align: 'center',
          },
          {
            title: '收款时间',
            key: 'flow_time',
            align: 'center',
          },
          {
            title: '备注',
            key: 'desc',
            align: 'center',
          },
          {
            title: '操作人员',
            key: 'operator_name',
            align: 'center',
          },
        ]
      },
      clientFormItem: {
        id: '',
        sale_no: '',
        client_id: '',
        client_name: '',
        include_tax: 2,
        invoicing_status:'',
        address: '',
        goods_setted: [],
        flow_id: '',
        certificate: [],
        remark: '',
        sale_date: '',
        logistics_id: '',
        logistics_no: '',
        logistics_pay: 0,
        pick_way: 1,//自提
        discount_amount: 0,
        goods_pay: 0,
        to_pay: 0,
        count_price: 0,//根据商品计算出的总价
        count_num: 0,//根据商品计算出的总数量
        uploadParam: {
          list_length: 3,
          upload_type: 2,//2表示凭证上传
          module_type: 3,//3表示销售
          prefix: '',
          list: [],
          key: 0,
        },
        return_goods_set: [],
        return_cost: 0,
        sale_person: 0,
        department_id: 0,
        real_pay: 0,
        pay_status: '',
        pay_desc: '',
        flow_info: [],
        return_flow_info:[],
      },
      clientList: [],
      logisticsList: [],
      pick_way:[
        {
          "id": 1,
          "name": '客户自提'
        },
        {
          "id": 2,
          "name": '物流'
        },
        {
          "id": 3,
          "name": '送货'
        },
      ],
    }
  },
  mounted() {
    this.getLogisticsAll();
    this.getClientList();
    this.getPayDescAll();

    this.special_rights_gross_profit = localStorage.getItem('special_rights_gross_profit');

  },
  created() {
    this.aCheck.plateName = 'sale_manage';
  },
  components: {
    ImagePreview,
  },
  methods: {
    //查看图片
    imgShow(value, img_info) {
      this.$refs.image_preview.imgPreview(value, img_info);
    },
    paySaleOperate(row) {
      this.pay_sale.modal_loading = true;
      if (row.current_pay ==0 || row.current_pay<0) {
        this.pay_sale.modal_loading = false;
        this.$Message.error('收款金额不能为0或负数！');
        return false;
      }

      this.$Modal.confirm({
        title: "收款确认提示",
        content: '收款操作即将执行，请确认填写无误？当收款金额加上已付金额大于等于订单金额时，订单将自动完结！',
        okText: '确定',
        cancelText: '取消',
        onOk: () => {
          let param = {
            link_id : row.id,
            pay_money: row.current_pay,
            pay_time: row.pay_time,
            remark: row.pay_desc,
          }
          apiPaySaleOperate(param).then(res => {
            if (res.data.err_no == 0) {
              this.$Message.success(res.data.results);
              this.pay_sale.modal = false;
              this.getSaleOrderById(row.id);
              this.$emit('paySaleOperate', row.id);
              this.pay_sale.modal_loading = false;
            } else {
              this.pay_sale.modal_loading = false;
              this.$Message.error(res.data.err_msg);
            }
          }).catch( error => {
            this.pay_sale.modal_loading = false;
          })
        },
        onCancel: () => {
          this.pay_sale.modal_loading = false;
        }
      });

    },
    closePaySale() {
      this.pay_sale.modal = false;
    },
    setPayDesc(item, name) {
      item.pay_desc = name;
    },
    getSalePayDateTime(value, type) {
      this.pay_sale.item.pay_time = value;
    },
    selectTap(flag) {
      this.common.view_flag = flag;
    },
    paySaleOrder(row) {
      this.pay_sale.item.id = row.id;
      this.pay_sale.item.goods_pay = row.goods_pay;
      this.pay_sale.item.real_pay = row.real_pay;
      this.pay_sale.item.current_pay = NP.minus(row.goods_pay, row.real_pay);
      this.pay_sale.item.remark = '';
      this.pay_sale.item.pay_time = '';
      this.pay_sale.modal = true;
    },
    handleResetOrderModal(item) {
      this.check_order.modal = false;
      item.modal = false;
    },
    getPayStatusColor(index) {
      let rs = '';
      switch (index) {
        case 2:
          rs = 'color:#ff9900';
          break;
        case 3:
        case 4:
          rs = 'color:#2775ff';
          break;
        case 1:
          rs = 'color:#ed4014';
          break;
        default:
          rs = '';
          break;
      }

      return rs;
    },
    //表格数据汇总
    addGoodsHandleSummary({columns, data}) {
      const sums = {};
      columns.forEach((column, index) => {
        const key = column.key;
        if (index === 0) {
          sums[key] = {
            key,
            value: '总计'
          };
          return;
        }
        const values = data.map(item => Number(item[key]));
        if (!values.every(value => isNaN(value)) && (index == 2 || index == 4)) {
          const v = values.reduce((prev, curr) => {
            const value = Number(curr);
            if (!isNaN(value)) {
              return NP.plus(prev, curr);
            } else {
              return prev;
            }
          }, 0);

          if (index === 2) {
            sums[key] = {
              key,
              value: '共 ' + v
            };
            this.clientFormItem.count_num = v;
          } else if (index === 4) {
            sums[key] = {
              key,
              value: '共 ' + v
            };
            this.clientFormItem.count_price = v;
          }
        } else {
          sums[key] = {
            key,
            value: ''
          };
        }
      });

      return sums;
    },
    getPayStatusText(index) {
      let rs = '';
      switch (index) {
        case 1:
          rs = '待收款';
          break;
        case 2:
          rs = '待收款';
          break;
        case 3:
          rs = '已收款';
          break;
        case 4:
          rs = '已收款';
          break;
        default:
          rs = '';
          break;
      }

      return rs;
    },
    getStatusText(index) {
      let rs = '';
      switch (index) {
        case 1:
          rs = '待提交';
          break;
        case 2:
          rs = '待出库';
          break;
        case 3:
          rs = '已出库';
          break;
        case 4:
          rs = '已提交';
          break;
        case 7:
          rs = '已发货';
          break;
        case 8:
          rs = '出库中';
          break;
        case 5:
          rs = '已取消';
          break;
        case 6:
          rs = '已退货';
          break;
        case 9:
          rs = '有退货';
          break;
        case 10:
          rs = '已完成';//（该状态表示订单有退货）
          break;
        case 11:
          rs = '有退款';
          break;
        default:
          rs = '';
          break;
      }

      return rs;
    },

    getStatusColorTg(index) {
      let rs = '';
      switch (index) {
        case 1:
          rs = 'background-color:#ed4014';
          break;
        case 2:
          rs = 'background-color:#19be6b';
          break;
        case 3:
        case 8:
        case 7:
          rs = 'background-color:#2db7f5';
          break;
        case 4:
        case 10:
          rs = 'background-color:#2775ff';
          break;
        case 5:
          rs = 'background-color:#c5c8ce';
          break;
        case 6:
        case 9:
        case 11:
          rs = 'background-color:#ff9900';
          break;
        default:
          rs = '';
          break;
      }

      return rs;
    },
    getPayDescAll() {
      apiGetPayDescAll().then(res => {
        if (res.data.err_no == 0) {
          this.common.pay_desc_list = res.data.results;
        }
      });
    },
    getClientList() {
      clientsUtil.getClientsAll().then(res => {
        this.clientList = res;
      })
    },
    getLogisticsAll() {
      logisticsUtil.getLogisticsAll().then(res => {
        this.logisticsList = res;
      });
    },
    minus(a, b) {
      return NP.minus(a, b)
    },
    //打开审核页面
    checkOrderInfo(id) {
      this.check_order.title = '查看订单信息';
      this.aCheck.plateName = 'sale_manage';
      this.getSaleOrderById(id);

      this.check_order.modal = true;
      this.modal_loading.success = false;
      this.common.view_flag = 1;
    },
    //获取销售单信息
    getSaleOrderById(id) {
      this.common.view_loading = true;
      this.$axios.get('/api/sale/getSaleOrderById?id=' + id).then((res) => {
        this.common.view_loading = false;
        let result = res.data.results;
        if (res.data.err_no == 0) {
          this.clientFormItem.id = result.id;
          this.clientFormItem.sale_no = result.sale_no;
          this.clientFormItem.status = result.status;
          this.clientFormItem.goods_num = parseFloat(result.goods_num);
          this.clientFormItem.goods_pay = parseFloat(result.goods_pay);
          this.clientFormItem.real_pay = parseFloat(result.real_pay);
          this.clientFormItem.gross_profit = parseFloat(result.gross_profit);
          this.clientFormItem.to_pay = NP.minus(this.clientFormItem.goods_pay, this.clientFormItem.real_pay)
          this.clientFormItem.pay_status = result.pay_status;
          this.clientFormItem.pay_desc = result.pay_desc;
          this.clientFormItem.client_id = result.client_id;
          this.clientFormItem.logistics_id = result.logistics_id == 0 ? '':result.logistics_id;
          this.clientFormItem.logistics_no = result.logistics_no;
          this.clientFormItem.include_tax = result.include_tax;
          this.clientFormItem.invoicing_status = result.invoicing_status;
          this.clientFormItem.logistics_pay = parseFloat(result.logistics_pay);
          this.clientFormItem.pick_way = result.pick_way;
          this.clientFormItem.address = result.address;
          this.clientFormItem.sale_date = result.sale_date;
          this.clientFormItem.certificate = result.certificate;
          this.clientFormItem.remark = result.remark;
          this.clientFormItem.discount_amount = parseFloat(result.discount_amount);

          for (let i=0; i<result.goods_setted.length; i++) {
            result.goods_setted[i].sale_num = parseFloat(result.goods_setted[i].sale_num);
            result.goods_setted[i].sale_price = parseFloat(result.goods_setted[i].sale_price);
            result.goods_setted[i].sale_money = parseFloat(result.goods_setted[i].sale_money);
          }
          this.clientFormItem.goods_setted = result.goods_setted;

          for (let i=0; i<result.flow_info.length; i++) {
            result.flow_info[i].money = parseFloat(result.flow_info[i].money);
          }
          this.clientFormItem.flow_info = result.flow_info;

          this.clientFormItem.uploadParam.prefix = this.clientFormItem.sale_no;
          this.clientFormItem.uploadParam.list = JSON.parse(JSON.stringify(this.clientFormItem.certificate));
          this.clientFormItem.uploadParam.key += 1;
        }
      });
    },

  }
}
</script>

<style scoped>
.select-flag {
  font-size: 16px;
  font-weight: 500;
}
</style>
