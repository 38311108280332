import request from '@/plugins/http'

export function createShipOutByGoodsOutId(param) {
    return request.post('/api/goods/createShipOutByGoodsOutId', param);
}

export function saveImportStockInBack(param) {
    return request.post('/api/goods/saveImportStockInBack', param);
}

export function getGoodsList(param) {
    return request.post('/api/goods/getGoodsList', param);
}

export function apiGetGoodsStockWarning() {
    return request.post('/api/goods/getGoodsStockWarning');
}

export function apiGetGoodsUpDown() {
    return request.post('/api/goods/getGoodsUpDown');
}

export function apiExportGoodsList(param) {
    return request.post('/api/goods/exportGoodsList', param);
}

export function apiGetGoodsStockInfo(param) {
    return request.post('/api/goods/getGoodsStockInfo', param);
}

export function apiGetGoodsExplodeNewId() {
    return request.post('/api/goods/getGoodsExplodeNewId');
}

export function apiCreateGoodsExplode(param) {
    return request.post('/api/goods/createGoodsExplode', param);
}

export function apiGetExplodeList(param) {
    return request.post('/api/goods/getExplodeList', param);
}

export function apiGetGoodsExplodeDetail(param) {
    return request.post('/api/goods/getGoodsExplodeDetail', param);
}

export function apiDeleteExplodeList(param) {
    return request.post('/api/goods/deleteExplodeList', param);
}

export function apiValidGoodsExplodeOperate(param) {
    return request.post('/api/goods/validGoodsExplodeOperate', param);
}

export function forceEditGoodsExplodeApi(param) {
    return request.post('/api/goods/forceEditGoodsExplode', param);
}



export function apiGetStockPreview() {
    return request.post('/api/goods/getStockPreview');
}






















