import request from '@/plugins/http'

export function createBuyOrder(param) {
    let config = {
        headers: {'Content-Type': 'multipart/form-data'},
        emulateJSON: true
    };  //添加请求头
    return request.post('/api/buy/createBuyOrder', param, config);
}

export function forceEditBuyOrderApi(param) {
    return request.post('/api/buy/forceEditBuyOrder', param);
}

export function arriveBuyOrderAndIn(param) {
    return request.post('/api/buy/arriveBuyOrderAndIn', param);
}

export function apiPayBuyOperate(param) {
    return request.post('/api/buy/payBuyOperate', param);
}

export function apiPayReturnBuyOperate(param) {
    return request.post('/api/buy/payReturnBuyOperate', param);
}

export function apiCheckImportGoodsValid(param) {
    return request.post('/api/goods/checkImportGoodsValid', param);
}

export function apiSetBuyPayStatusEnd(param) {
    return request.post('/api/buy/setBuyPayStatusEnd', param);
}

export function apiSetBuyReturnPayStatusEnd(param) {
    return request.post('/api/buy/setBuyReturnPayStatusEnd', param);
}

export function apiGetBuyClientMoney(param) {
    return request.post('/api/buy/getBuyClientMoney', param);
}

export function apiGetBuyClientDetail(param) {
    return request.post('/api/buy/getBuyClientDetail', param);
}

export function apiSetInvoicingStatusDone(param) {
    return request.post('/api/buy/setInvoicingStatusDone', param);
}

export function apiBuyForceDeleteOperate(param) {
    return request.post('/api/buy/buyForceDeleteOperate', param);
}













