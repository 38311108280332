<template>
<div>
  <!--审核-->
  <Modal v-model="checkOrderModal" :title="checkOrderModalTitle" width="1280">
    <div class="modalForm">
      <Form v-show="!common.view_loading" :model="clientFormItem" :label-width="100" label-colon>
        <Row :gutter="10">
          <Col span="8">
            <FormItem label="采购单号" prop="buy_no">
              <span>{{ clientFormItem.buy_no }}{{clientFormItem.part_no_postfix ? ('-' + clientFormItem.part_no_postfix) : '' }}</span>
            </FormItem>
          </Col>
          <Col span="8">
            <FormItem label="供应商" prop="supplier_id">
              <span v-for="item in supplierList" v-show="item.id==clientFormItem.supplier_id">{{ item.client_name }}</span>
            </FormItem>
          </Col>
          <Col span="8">
            <FormItem label="采购时间" prop="buy_date">
              <span>{{ clientFormItem.buy_date }}</span>
            </FormItem>
          </Col>
        </Row>
        <Row :gutter="10">
          <Col span="8">
            <FormItem label="物流" prop="logistics_id">
              <span v-for="item in logisticsList" v-show="item.id==clientFormItem.logistics_id">{{ item.client_name }} ({{item.contact_person }})</span>
            </FormItem>
          </Col>
          <Col span="8">
            <FormItem label="物流单号" prop="logistics_order_id">
              <span>{{ clientFormItem.logistics_order_id }}</span>
            </FormItem>
          </Col>
          <Col span="8">
            <FormItem label="物流运费" prop="logistics_pay">
              <span>{{ clientFormItem.logistics_pay }}</span>
            </FormItem>
          </Col>
        </Row>
        <Row :gutter="10">
          <Col span="8">
            <FormItem label="含税" prop="include_tax">
              <span v-if="clientFormItem.include_tax == 1" class="money-color">是</span>
              <span v-else>否</span>
            </FormItem>
          </Col>
          <Col span="8">
            <FormItem label="收票状态" prop="invoicing_status">
              <span v-if="clientFormItem.invoicing_status == 1" class="main-font-color">已收票</span>
              <span v-if="clientFormItem.invoicing_status == 2" class="danger-color">待收票</span>
              <span v-if="clientFormItem.invoicing_status == 0">暂无</span>
            </FormItem>
          </Col>
          <Col span="8">
            <FormItem label="备注" prop="remark">
              <span>{{ clientFormItem.remark }}</span>
            </FormItem>
          </Col>
        </Row>
        <Row :gutter="10">
          <Col span="8">
            <FormItem label="凭证" prop="certificate">
              <div class="pointer image-upload-list" v-if="clientFormItem.certificate" v-for="(item, key)  in clientFormItem.certificate">
                <img :src="item.url" alt="" @click="imgShow(key, clientFormItem.certificate)" style="width:100%;height:100%">
              </div>
            </FormItem>
          </Col>
          <Col span="8">
            <FormItem label="单据状态" prop="status">
              <Tag :style="getStatusColorTg(clientFormItem.status)" class="white-font-color">{{ getStatusText(clientFormItem.status) }}</Tag>
            </FormItem>
          </Col>
          <Col span="8">
            <FormItem label="付款状态" prop="status">
              <span :style="getPayStatusColor(clientFormItem.pay_status)">{{ getPayStatusText(clientFormItem.pay_status) }}</span>
<!--              <Button type="text" @click="setPayStatusFinish(clientFormItem)" class="status-main fs-12" v-if="clientFormItem.pay_status == 2 && ![1,5].includes(clientFormItem.status)">-->
<!--                <Icon type="ios-checkmark-circle"/>-->
<!--                设为完结状态-->
<!--              </Button>-->
            </FormItem>
          </Col>
        </Row>
        <Row class="display-block">
          <Card dis-hover >
            <div>
              <span style="margin:0 10px" :class="common.view_flag === 1 ? 'select-flag main-font-color pointer': 'pointer'" @click="selectTap(1)">采购商品</span>
              <span style="margin:0 10px" :class="common.view_flag === 3 ? 'select-flag main-font-color pointer': 'pointer'" @click="selectTap(3)">实付金额记录</span>
              <Divider style="margin: 14px 0px;"/>
            </div>
            <div v-show="common.view_flag == 1">
              <Table :columns="table_columns_modal" :data="clientFormItem.goods_setted" show-summary
                     :summary-method="addGoodsHandleSummary">
                <template slot-scope="{ row, index }" slot="part_name">
                  <span class="money-color fw-bold fs-12" v-show="row.free_flag==1">[赠]</span>
                  <span>{{row.part_name}}</span>
                </template>
                <template slot-scope="{ row, index }" slot="wholesale_price">
                  <span>{{ row.wholesale_price }}</span>
                </template>

                <template slot-scope="{ row, index }" slot="selling_price">
                  <span>{{ row.selling_price }}</span>
                </template>

                <template slot-scope="{ row, index }" slot="buy_num">
                  <span>{{ row.buy_num }}</span>
                </template>
                <template slot-scope="{ row, index }" slot="buy_price">
                  <span>{{ row.buy_price }}</span>
                </template>

                <template slot-scope="{ row, index }" slot="action">
                  <span class="disabled-color fs-12">------</span>
                </template>
              </Table>
            </div>
            <div v-show="common.view_flag == 3">
              <Table :columns="common.real_pay_columns" :data="clientFormItem.flow_info">
                <template slot-scope="{ row, index }" slot="money">
                  <strong>{{ row.money }}</strong>
                </template>
              </Table>
            </div>
          </Card>
        </Row>

        <div class="" style="display:flex;justify-content: space-around;">
          <FormItem label="优惠" prop="discount_amount" class="mb-0">
            <span>{{ clientFormItem.discount_amount }}</span>
          </FormItem>
          <FormItem label="物流运费" prop="logistics_pay" class="mb-0">
            <span>{{ clientFormItem.logistics_pay }}</span>
          </FormItem>
          <!--            <FormItem label="采购金额" prop="summary" class="mb-0">-->
          <!--              <strong class="main-font-color">{{common.summary}}</strong>-->
          <!--            </FormItem>-->
          <FormItem label="采购总额" prop="goods_pay" class="mb-0">
            <strong class="main-font-color">{{ clientFormItem.goods_pay }}</strong>
          </FormItem>

          <FormItem label="本单实付" prop="goods_pay" class="mb-0">
            <strong class="main-font-color">{{ clientFormItem.real_pay }}</strong>
            <!--              <span v-show="clientFormItem.status == 1 && clientFormItem.pay_desc" class="fs-12 ml-5">({{clientFormItem.pay_desc}})</span>-->
          </FormItem>

          <FormItem label="本单欠款" prop="to_pay" class="mb-0" >
            <strong class="money-color">{{clientFormItem.to_pay}}</strong>
          </FormItem>
        </div>
      </Form>
      <Spin fix v-show="common.view_loading" size="large"></Spin>
    </div>
    <div slot="footer">
      <Button @click="handleResetOrderModal(clientFormItem)">关闭</Button>

      <a target="_blank" style="margin:0 8px;" v-show="clientFormItem.status==4" :href="'/BuyPrint?printType=buy&no='+clientFormItem.id" v-if="aCheck.rightCheck('operate_procurement_list')">
        <Button type="primary">打印预览</Button>
      </a>


      <Button type="warning" v-show="[0,1,2].includes(clientFormItem.pay_status) && clientFormItem.status!=1" @click="payBuyOrder(clientFormItem)" v-if="aCheck.rightCheck('operate_procurement_list')">
        付款
      </Button>
    </div>
  </Modal>

  <!--采购付款-->
  <Modal v-model="pay_buy.modal" title="付款操作" width="800">
    <div class="modalForm">
      <Form ref="pay_buy_item" :model="pay_buy.item" :label-width="100" label-colon>
        <Row :gutter="20">
          <Col span="6"></Col>
          <Col span="12">
            <FormItem label="订单金额" prop="goods_pay">
              <strong>{{pay_buy.item.goods_pay}}</strong>
            </FormItem>
          </Col>
          <Col span="6"></Col>
        </Row>
        <Row :gutter="20">
          <Col span="6"></Col>
          <Col span="12">
            <FormItem label="已付金额" prop="goods_pay">
              <span class="main-font-color">{{pay_buy.item.real_pay}}</span>
            </FormItem>
          </Col>
          <Col span="6"></Col>
        </Row>
        <Row :gutter="20">
          <Col span="6"></Col>
          <Col span="12">
            <FormItem label="付款金额" prop="current_pay">
              <Input v-model="pay_buy.item.current_pay" placeholder="请填写付款金额" type="number"></Input>
            </FormItem>
          </Col>
          <Col span="6"></Col>
        </Row>
        <Row :gutter="20">
          <Col span="6"></Col>
          <Col span="12">
            <FormItem label="付款时间" prop="pay_time">
              <DatePicker :value="pay_buy.item.pay_time" type="datetime"
                          format="yyyy-MM-dd HH:mm:ss"
                          placeholder="请填写付款时间(不填，默认当前时间)" style="width: 275px"
                          @on-change="getBuyPayDateTime"></DatePicker>
            </FormItem>
          </Col>
          <Col span="6"></Col>
        </Row>
        <Row :gutter="20">
          <Col span="6"></Col>
          <Col span="12">
            <FormItem label="说明" prop="remark">
              <Input v-model="pay_buy.item.pay_desc" placeholder="请填写说明"></Input>
              <div v-for="item in common.pay_desc_list" @click="setPayDesc(pay_buy.item, item.name)" class="ivu-tag ivu-tag-size-default ivu-tag-default ivu-tag-checked pointer">
                <span class="c-515a6e">{{ item.name }}</span>
              </div>
            </FormItem>
          </Col>
          <Col span="6"></Col>
        </Row>
      </Form>
    </div>
    <div slot="footer">
      <Button @click="closePayBuy">关闭</Button>
      <Button type="primary" :loading="pay_buy.modal_loading" @click="payBuyOperate(pay_buy.item)">确认付款</Button>
    </div>
  </Modal>

  <!--图片预览-->
  <ImagePreview ref="image_preview"/>

</div>
</template>

<script>
import NP from "number-precision";
import * as supplier_utils from "@/utils/supplier";
import * as logistics_util from "@/utils/logistics";
import {apiPayBuyOperate} from "@/api/buy/buy";
import {apiGetPayDescAll} from "@/api/setting/payDesc";
import ImagePreview from '@/components/common/Image/ImagePreview';

export default {
  name: "BuyDetail",
  data() {
    return {
      modal_loading: {
        success: false,
        add_edit: false,
      },
      pay_buy: {
        modal:false,
        modal_loading: false,
        item: {
          id: 0,
          real_pay: 0,
          goods_pay: 0,
          current_pay: '',
          pay_time: '',
          pay_desc: '',
        }
      },
      logisticsList: [],
      supplierList: [],
      table_columns_modal: [
        {
          title: '商品编号',
          key: 'part_no',
          width:100,
          align: 'center',
        },
        {
          title: '商品名称',
          key: 'part_name',
          slot: 'part_name',
          minWidth:260,
          align: 'left',
        },
        {
          title: '批发价',
          key: 'wholesale_price',
          slot: 'wholesale_price',
          align: 'center',
        },
        {
          title: '销售价',
          key: 'selling_price',
          slot: 'selling_price',
          align: 'center',
        },
        {
          title: '采购数量',
          key: 'buy_num',
          slot: 'buy_num',
          align: 'center',
        },
        {
          title: '单价',
          key: 'buy_price',
          slot: 'buy_price',
          align: 'center',
        },
        {
          title: '合计',
          key: 'buy_money',
          align: 'center',
        },
        {
          title: '操作',
          key: 'action',
          width: 180,
          slot: 'action',
          align: 'center',
        }
      ],
      checkOrderModalTitle: '提交信息',
      modal1: false,
      checkOrderModal: false,
      clientFormItem: {
        invoicing_status: 0,
        to_pay:0,
        id: '',
        buy_no: '',
        part_no_postfix: '',
        supplier_id: '',
        logistics_id: 0,
        project: 0,
        logistics_order_id: '',
        logistics_pay: 0,
        include_tax: 2,
        goods_setted: [],
        flow_id: '',
        certificate:[],
        remark: '',
        buy_date: '',
        discount_amount: 0,
        goods_pay: 0,
        uploadParam: {
          list_length: 3,
          upload_type: 2,//2表示凭证上传
          module_type: 4,//4表示采购文件夹
          prefix: '',
          list: [],
          key: 0,
        },
        return_goods_set:[],
        return_cost: 0,
        real_pay:0,
        pay_desc:''
      },
      common: {
        pay_desc_list:[],
        summary: 0,
        list_loading: false,
        view_loading: false,
        view_flag: 1,
        real_pay_columns: [
          {
            title: '流水单号',
            key: 'flow_id',
            align: 'center',
          },
          {
            title: '实付金额',
            key: 'money',
            slot: 'money',
            align: 'center',
          },
          {
            title: '付款时间',
            key: 'flow_time',
            align: 'center',
          },
          {
            title: '说明',
            key: 'desc',
            align: 'center',
          },
          {
            title: '操作人员',
            key: 'operator_name',
            align: 'center',
          },
        ]
      },
    }
  },
  mounted() {
    this.getLogisticsList();
    this.getSupplierList();
    // this.getAddressList();
    this.getPayDescAll();
  },
  methods: {
    //查看图片
    imgShow(value, img_info) {
      this.$refs.image_preview.imgPreview(value, img_info);
    },
    getPayDescAll() {
      apiGetPayDescAll().then(res => {
        if (res.data.err_no == 0) {
          this.common.pay_desc_list = res.data.results;
        }
      });
    },
    setPayDesc(item, name) {
      item.pay_desc = name;
    },
    payBuyOperate(row) {
      this.pay_buy.modal_loading = true;
      if (row.current_pay ==0 || row.current_pay<0) {
        this.pay_buy.modal_loading = false;
        this.$Message.error('付款金额不能为0或负数！');
        return false;
      }

      this.$Modal.confirm({
        title: "付款确认提示",
        content: '付款操作即将执行，请确认填写无误？当付款金额加上已付金额大于等于订单金额时，订单将自动完结！',
        okText: '确定',
        cancelText: '取消',
        onOk: () => {
          let param = {
            link_id : row.id,
            pay_money: row.current_pay,
            pay_time: row.pay_time,
            remark: row.pay_desc,
          }
          apiPayBuyOperate(param).then(res => {
            if (res.data.err_no == 0) {
              this.$Message.success(res.data.results);
              this.pay_buy.modal = false;
              this.getBuyOrderById(row.id);
              this.$emit('payBuyOperate', row.id);
              this.pay_buy.modal_loading = false;
            } else {
              this.pay_buy.modal_loading = false;
              this.$Message.error(res.data.err_msg);
            }
          }).catch( error => {
            this.pay_buy.modal_loading = false;
          })
        },
        onCancel: () => {
          this.pay_buy.modal_loading = false;
        }
      });

    },
    closePayBuy() {
      this.pay_buy.modal = false;
    },
    getBuyPayDateTime(value, type) {
      this.pay_buy.item.pay_time = value;
    },
    getStatusColorTg(index) {
      let rs = '';
      switch (index) {
        case 1:
          rs = 'background-color:#ed4014';
          break;
        case 2:
          rs = 'background-color:#19be6b';
          break;
        case 3:
          rs = 'background-color:#2db7f5';
          break;
        case 4:
        case 8:
          rs = 'background-color:#2775ff';
          break;
        case 5:
          rs = 'background-color:#c5c8ce';
          break;
        case 6:
        case 7:
          rs = 'background-color:#ff9900';
          break;
        default:
          rs = '';
          break;
      }

      return rs;
    },
    getStatusText(index) {
      let rs = '';
      switch (index) {
        case 1:
          rs = '待提交';
          break;
        case 2:
          rs = '待收货';
          break;
        case 3:
          rs = '待入库';
          break;
        case 4:
        case 8:
          rs = '已提交';
          break;
        case 5:
          rs = '已取消';
          break;
        case 6:
          rs = '已退货';
          break;
        case 7:
          rs = '有退货';
          break;
        default:
          rs = '';
          break;
      }

      return rs;
    },
    getPayStatusColor(index) {
      let rs = '';
      switch (index) {
          // case 2:
          //   rs = 'color:#ff9900';
          //   break;
        case 3:
        case 4:
          rs = 'color:#2775ff';
          break;
        case 1:
        case 2:
          rs = 'color:#ed4014';
          break;
        default:
          rs = '';
          break;
      }

      return rs;
    },
    getPayStatusText(index) {
      let rs = '';
      switch (index) {
        case 1:
          rs = '待付款';
          break;
        case 2:
          rs = '待付款';
          break;
        case 3:
          rs = '已付款';
          break;
        case 4:
          rs = '已付款';
          break;
        case 5:
          rs = '无需付款';
          break;
        default:
          rs = '';
          break;
      }

      return rs;
    },
    getLogisticsList() {
      logistics_util.getLogisticsAll().then(res => {
        this.logisticsList = res;
      });
    },
    //获取供应商列表
    getSupplierList() {
      supplier_utils.getSupplierAll().then(res => {
        this.supplierList = res;
      });
    },
    addGoodsHandleSummary({columns, data}) {
      //表格数据汇总
      const sums = {};
      columns.forEach((column, index) => {
        const key = column.key;
        if (index === 0) {
          sums[key] = {
            key,
            value: '总计'
          };
          return;
        }
        const values = data.map(item => Number(item[key]));
        if (!values.every(value => isNaN(value)) && (index == 4 || index == 6)) {
          const v = values.reduce((prev, curr) => {
            const value = Number(curr);
            if (!isNaN(value)) {
              return NP.plus(prev, curr);
            } else {
              return prev;
            }
          }, 0);

          if (index == 4) {
            sums[key] = {
              key,
              value: '共 ' + v
            };
          } else if (index == 6) {
            sums[key] = {
              key,
              value: '共 ' + v
            };
            this.common.summary = v;//临时记录计算总价
          }

        } else {
          sums[key] = {
            key,
            value: ''
          };
        }
      });

      return sums;
    },
    //审核弹出框重置
    handleResetOrderModal(name) {
      this.checkOrderModal = false;
      name.modal = false;
    },
    payBuyOrder(row) {
      this.pay_buy.item.id = row.id;
      this.pay_buy.item.goods_pay = row.goods_pay;
      this.pay_buy.item.real_pay = row.real_pay;
      this.pay_buy.item.current_pay = NP.minus(row.goods_pay, row.real_pay);
      this.pay_buy.item.remark = '';
      this.pay_buy.item.pay_time = '';
      this.pay_buy.modal = true;
    },

    //打开审核页面
    checkOrderInfo(id) {
      this.checkOrderModalTitle = '查看订单信息';
      this.aCheck.plateName = 'buy_manage';

      this.getBuyOrderById(id);

      this.checkOrderModal = true;
      //加载按钮关闭
      this.modal_loading.success = false;
    },
    //获取采购单信息
    getBuyOrderById(id) {
      this.common.view_loading = true;
      this.$axios.get('/api/buy/getBuyOrderById?id=' + id).then((res) => {
        this.common.view_loading = false;
        let result = res.data.results;
        if (res.data.err_no == 0) {
          this.clientFormItem.id = result.id;
          this.clientFormItem.buy_no = result.buy_no;
          this.clientFormItem.status = result.status;
          this.clientFormItem.goods_num = result.goods_num;
          this.clientFormItem.goods_pay = parseFloat(result.goods_pay);
          this.clientFormItem.real_pay = parseFloat(result.real_pay);
          this.clientFormItem.to_pay = NP.minus(this.clientFormItem.goods_pay, this.clientFormItem.real_pay)
          this.clientFormItem.pay_status = parseFloat(result.pay_status);
          this.clientFormItem.pay_desc = result.pay_desc;
          this.clientFormItem.part_no_postfix = result.part_no_postfix;
          this.clientFormItem.supplier_id = result.supplier_id;
          this.clientFormItem.project = result.project;
          this.clientFormItem.logistics_id = result.logistics_id;
          this.clientFormItem.logistics_pay = parseFloat(result.logistics_pay);
          this.clientFormItem.logistics_order_id = result.logistics_order_id;
          this.clientFormItem.include_tax = result.include_tax;
          this.setTableColumnsModal(result.include_tax);
          this.clientFormItem.invoicing_status = result.invoicing_status;
          this.clientFormItem.flow_id = result.flow_id;
          this.clientFormItem.certificate = result.certificate == '[]' ? []: result.certificate;

          this.clientFormItem.remark = res.data.results.remark;
          this.clientFormItem.buy_date = result.buy_date;
          this.clientFormItem.discount_amount = parseFloat(result.discount_amount);

          for(let i=0; i<result.goods_setted.length; i++) {
            result.goods_setted[i].buy_num = parseFloat(result.goods_setted[i].buy_num);
            result.goods_setted[i].buy_money = parseFloat(result.goods_setted[i].buy_money);
            result.goods_setted[i].selling_price = parseFloat(result.goods_setted[i].selling_price);
            result.goods_setted[i].wholesale_price = parseFloat(result.goods_setted[i].wholesale_price);
            result.goods_setted[i].stock_all = parseFloat(result.goods_setted[i].stock_all);
          }
          this.clientFormItem.goods_setted = result.goods_setted;

          this.clientFormItem.return_goods_set = [];
          this.clientFormItem.return_cost = 0;
          if (result.return_goods_set !== undefined) {
            this.clientFormItem.return_goods_set = result.return_goods_set;
            this.clientFormItem.return_cost = parseFloat(result.return_cost);
          }

          for (let i=0; i<result.flow_info.length; i++) {
            result.flow_info[i].money = parseFloat(result.flow_info[i].money);
          }
          this.clientFormItem.flow_info = result.flow_info

          this.clientFormItem.uploadParam.prefix = this.clientFormItem.buy_no;
          this.clientFormItem.uploadParam.list = JSON.parse(JSON.stringify(this.clientFormItem.certificate));
          this.clientFormItem.uploadParam.key += 1;
        }
      });
    },
    setTableColumnsModal(includeTax) {
      for(let i=0; i<this.table_columns_modal.length; i++) {
        if (this.table_columns_modal[i].key == 'buy_price') {
          this.table_columns_modal[i].title = '单价';
          if (includeTax == 1) {
            this.table_columns_modal[i].title = '含税单价';
          }
        }

        if (this.table_columns_modal[i].key == 'buy_money') {
          this.table_columns_modal[i].title = '合计';
          if (includeTax == 1) {
            this.table_columns_modal[i].title = '价税合计';
          }
        }
      }
    },
    selectTap(flag) {
      this.common.view_flag = flag;
    },

  },
  created() {
    this.aCheck.plateName = 'buy_manage';
  },
  components: {
    ImagePreview,
  }
}
</script>

<style scoped>
.select-flag {
  font-size: 16px;
  font-weight: 500;
}
</style>
